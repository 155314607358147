import Head from "next/head";

export default function Home() {
  return (
    <div className="w-screen h-screen">
      <Head>
        <title>Pronto App</title>
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          href="https://sumofus.imgix.net/unique/favicon-avatar.png"
          type="image/png"
          sizes="32x32"
        />
        <link
          rel="mask-icon"
          href="https://sumofus.imgix.net/unique/favico.svg"
          type="image/png"
          sizes="32x32"
        />
      </Head>
    </div>
  );
}
